function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {
        // Three options for user to choose from
        $('.situation').click(function () {
            if (!$(this).hasClass('active-situation')) {
                $(this).addClass('active-situation')
                $('.situation').not($(this)).removeClass('active-situation');

                if ($(this).hasClass('situation-1')) {
                    changeOptions('1');
                } else if ($(this).hasClass('situation-2')) {
                    changeOptions('2');
                } else {
                    changeOptions('3');
                }
            }
        });

       function changeOptions($chosen_situation) {
            $chosenOptions = $('.options-' + $chosen_situation);
            if ($chosenOptions.hasClass('hidden')) {
                $chosenOptions.addClass('active');
                $chosenOptions.removeClass('hidden');
            }
            $otherOptions = $('.lifts-container');
            if (!$otherOptions.hasClass('.options-' + $chosen_situation)) {
                $otherOptions.not($chosenOptions).removeClass('active');
                $otherOptions.not($chosenOptions).addClass('hidden');
            }
        }

    });
})(jQuery);
